import { onAjax } from "./auth";

var userData = undefined;

export const setUserData = async () => {
    userData = await getUserMe();
    console.log(`### setUserData userData ${JSON.stringify(userData)}`);
};

export const getUserData = async () => {
    console.log('### getUserData iniciado');
    console.log(`### getUserData userData === undefined: ${userData === undefined}`);
    if (userData === undefined) {
        await setUserData();
    };
    console.log('### getUserData userData', userData);
    console.log('### getUserData finalizado');
    return userData;
};

export const getUserMe = async () => {
    const response = await onAjax('GET', `users/me`);
    return response;
};

export const setUserPresence = async (userId, precenseId) => {

    const body = {
        "presenceDefinition": {
            "id": `${precenseId}`
        },
    }
    const response = await onAjax('PATCH', `users/${userId}/presences/purecloud`, body)
    return response
}
