import $ from 'jquery';

const clientId = `${process.env.REACT_APP_CLIENT_ID}`;
const redirectUri = `${process.env.REACT_APP_REDIRECT_URI}`;
const API_URL = `${process.env.REACT_APP_API_URL}`
const auth_url = `${process.env.REACT_APP_AUTH_URL}`

function sleep(ms) {
    console.log(`### sleep for ${ms}ms`);
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const onAjax = async (tipo, path, dados) => {
    const token = await isAuthenticated();
    return $.ajax({
        url: API_URL + path,
        type: tipo,
        data: JSON.stringify(dados),
        beforeSend: function(xhr) {
            xhr.setRequestHeader('Authorization', 'bearer ' + token);
            xhr.setRequestHeader('Content-Type', 'application/json');
        },
    });
}

export const generateToken = () => {
    var urlAtual = window.location.href;
    console.log(`### generateToken urlAtual ${urlAtual}`);
    var index = urlAtual.indexOf("#");
    var parametrosString = urlAtual.slice(index + 1);
    var parametrosArray = parametrosString.split("&");
    var access_token = null;
    for (var i = 0; i < parametrosArray.length; i++) {
        var parametro = parametrosArray[i].split("=");
        if (parametro[0] === "access_token") {
            access_token = parametro[1];
            console.log(`### generateToken access_token ${access_token}`);
            break;
        }
    }
    const token = sessionStorage.getItem('token')
    console.log(`### generateToken getItem ${token}`);
    sessionStorage.setItem('token', access_token ? access_token : token)
    console.log(`### generateToken getItem ${token}`);
    if (access_token) {
        console.log(`### generateToken return if ${access_token}`);
        return access_token;
    } else {
        console.log(`### generateToken return else ${token}`);
        return token;
    }
}

export const isAuthenticated = async () => {
    try {
        console.log('### Response: Iniciando isAuthenticated')

        var client_id = sessionStorage.getItem('clientId')
        console.log(`### isAuthenticated client_id ${client_id}`);
        if (client_id !== undefined && client_id !== null && client_id !== '') {
            const access_token = generateToken()
            console.log('### Response: Finalizando isAuthenticated')
            console.log('### access_token ', access_token)
            return access_token;
        }

        sessionStorage.setItem('clientId', clientId)
        const authorizationUrl = `${auth_url}/oauth/authorize?client_id=${clientId}&response_type=token&redirect_uri=${redirectUri}`
        window.location.href = authorizationUrl;
        console.log('### authorizationUrl ', authorizationUrl)

        await waitUrlWithToken();

        console.log('### Response: Finalizando isAuthenticated')
    } catch (error) {
        console.error(`### Error: ${error.message}`);
    }
}

const waitUrlWithToken = async (retries = 3) => {
    if (retries > 0) {
        console.log('### waitUrlWithToken iniciado');
        var token = generateToken();
        console.log('### waitUrlWithToken token', token);
        if (token === null || token === 'null') {
            console.log('### waitUrlWithToken rechamada');
            console.log('### waitUrlWithToken sleep');
            await sleep(1000);
            console.log('### waitUrlWithToken sleep fim');
            await waitUrlWithToken(retries--);
        }
        console.log('### waitUrlWithToken finalizado');
    }
}
