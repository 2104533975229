import React, { useEffect, useState } from 'react';
import emitter from '../events/config';

import { AlertModal } from './modal';
import { Screen } from './screen';

export const Hub = ({ eventSocket }) => {
    const [isCallBack, setIsCallBack] = useState(false);
    const [telefone, setTelefone] = useState(null);
    /* const [eventSocketParams, setEventSocketParams] = useState(eventSocket); */

    function handleTelefoneUpdate(data) {
        setTelefone(data);
    }

    const handleIsCallBack = (data) => {
        setIsCallBack(data)
        console.log('### disconnectType Hub ', isCallBack)
    }

    /* useEffect(() => {
        console.log('### eventSocket atualizado:', eventSocket);
        setEventSocketParams(eventSocket);
    }, [eventSocket]); */

    useEffect(() => {
        emitter.on('isCallBack', handleIsCallBack)
        console.log('### isCallBack montado')

        return () => {
            console.log('### isCallBack: desmontado')
            emitter.off('isCallBack', handleIsCallBack)
        };
    }, [])


    return (
        <div>
            {isCallBack ? <AlertModal disconnectType={isCallBack} updateTelefone={handleTelefoneUpdate} telefone={telefone} />
                : <Screen eventSocket={eventSocket} updateTelefone={handleTelefoneUpdate} telefone={telefone} />}
        </div>
    );
};

