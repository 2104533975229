import './App.css';
import { useEffect, useState } from 'react';
import { startflow } from './workflow';
import { Hub } from './view/hub.js';
import Loader from './view/loader.js';

function App() {
    const [startFlowCalled, setStartFlowCalled] = useState(false);
    const [eventSocket, setEventSocket] = useState(false);

    useEffect(() => {
        const initiateFlow = async () => {
            console.log('### Response: Iniciando UseEffect App');
            const result = await startflow();  // Inicia o fluxo que emite eventos para o Loader
            setEventSocket(result);
            setStartFlowCalled(true)
            console.log('### startflow result ', result);
            console.log('### Response: Finalizando UseEffect App');
            console.log('### App startFlowCalled ', startFlowCalled);
            console.log('### App eventSocket ', eventSocket);
        };
        initiateFlow().catch((e) => {
            console.error(`### Catch InitiateFlow: ${JSON.stringify(e)}`);
            sessionStorage.removeItem("clientId");
        });

    }, [startFlowCalled]);

    return (
        <div className="App">
            {startFlowCalled ? (
                <Hub eventSocket={eventSocket} />
            ) : (
                <Loader eventSocket={eventSocket} startFlowCalled={startFlowCalled} setStartFlowCalled={setStartFlowCalled}/>
            )}
        </div>
    );
}

export default App;
