import { createChannels } from "./notifications";
import { isAuthenticated } from "../services/auth";


export const connectSocket = async (retries = 3) => {
    try {
        console.log('### Response: Iniciando connectSocket')
        const channel = await createChannels()
        const connectUri = channel.connectUri
        const newSocketEnd = new WebSocket(connectUri);
        console.log('### connectSocket newSocketEnd: ', newSocketEnd)
        console.log('### connectSocket channel: ', channel)
        console.log('### Response: Finalizando connectSocket')
        return { newSocketEnd, channel };
    } catch (error) {
        if (retries > 0) {
            sessionStorage.removeItem("clientId");
            isAuthenticated();
            await connectSocket(retries - 1);
        }
    }
}
