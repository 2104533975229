import React, { useEffect, useState } from 'react';
import '../App.css';
import emitter from '../events/config';

const Loader = ({ eventSocket, startFlowCalled, setStartFlowCalled }) => {
    const [progress, setProgress] = useState(0);

    const sumProcess = (checkProcess) => {
        let targetProgress = 0;
        if (checkProcess === 'isAuthenticated') targetProgress = 50;
        if (checkProcess === 'validateData') targetProgress = 75;
        if (checkProcess === 'signChannelsEnd') targetProgress = 100;

        const interval = setInterval(() => {
            setProgress(prev => (prev < targetProgress ? prev + 1 : prev));
            if (progress >= targetProgress) clearInterval(interval);
        }, 100);
    };

    useEffect(() => {
        console.log('### progress ', progress)
        if (progress >= 100) {
            console.log('### Loader setStartFlowCalled = true');
            setStartFlowCalled(true);
        }

        if(eventSocket || startFlowCalled){
            setProgress(100);
        }
    }, [progress, startFlowCalled]);

    useEffect(() => {
        emitter.on('isAuthenticated', sumProcess);
        emitter.on('validateData', sumProcess);
        emitter.on('signChannelsEnd', sumProcess);

        return () => {
            emitter.off('isAuthenticated', sumProcess);
            emitter.off('validateData', sumProcess);
            emitter.off('signChannelsEnd', sumProcess);
        };
    }, []);

    return (
        <div id="loader-container">
            <div className="gif-container">
                <img className="speed-in" width={250} height={250} src={require('../assets/Speed-in.gif')} alt="Speed in" />
                <img className="speed-mind" width={250} height={250} src={require('../assets/Speed-mind.gif')} alt="Speed mind" />
                <img className="speed-out" width={250} height={250} src={require('../assets/Speed-out.gif')} alt="Speed out" />
            </div>
            <div className="loader">
                <div className="loader-bar" style={{ width: `${progress}%` }}></div>
            </div>
            <p className="textLoad">Por favor aguarde ({progress}%)<span className="dots"><span>.</span><span>.</span><span>.</span></span></p>
        </div>
    );
};

export default Loader;
