import { onAjax } from "./auth"

export const createChannels = async () => {
    const response = onAjax('POST', `notifications/channels`)
    return response;
}

export const signChannelsEnd = async (conversationId, channelId) => {
    // v2.detail.events.conversation.${conversationId}.user.start
    const body = [{ "id": `v2.detail.events.conversation.${conversationId}.user.end` }]
    const response = onAjax('POST', `notifications/channels/${channelId}/subscriptions`, body)
    return response;
}

export const singChannelsConversation = async (userId, channelId) => {
    // v2.users.{id}.conversations.calls
    const body = [{ "id": `v2.users.${userId}.conversations.calls` }]
    const response = onAjax('POST', `notifications/channels/${channelId}/subscriptions`, body)
    return response;
}