import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { useEffect, useState, useRef } from 'react';
import { clearLocalStorage, executeCallNow, setAgentInQueue } from '../scripts';
import { createCallbackConversation } from '../services/conversation';

export const AlertModal = ({ updateTelefone, telefone, disconnectType }) => {
    const [btnBlocked, setBtnBlock] = useState(false);
    const [disconnectTypeState, setDisconnectType] = useState(disconnectType);
    // const [telephoneReturn, setTelephone] = useState(null);
    const timerId = useRef(null);

    const initTimer = () => {
        timerId.current = setTimeout(async () => {
            console.log('### disconnectType Modal ', disconnectType)
            setBtnBlock(true);
            await createCallbackConversation(telefone)
        }, 8500)
        return timerId.current
    }

    useEffect(() => {
        initTimer();
        return () => {
            if (timerId.current) {
                clearTimeout(timerId.current)
            }
        };
    }, [])

    useEffect(() => {
        if (disconnectType !== disconnectTypeState) {
            setDisconnectType(disconnectType);
            console.log('### disconnectTypeState alterado')
        }
        if(disconnectTypeState === 'transfer' || disconnectTypeState === 'client'){
            clearTimeout(timerId.current)
            console.log('### timeout cancelado')
        }
        console.log('### disconnectTypeState Modal useEffect ', disconnectTypeState)
    }, [disconnectTypeState, disconnectType])

    return (
        <Modal show={true}>
            <Modal.Header closeButton>
                <img width={200} height={55} src={require('../assets/neo/neologo.png')} />
            </Modal.Header>
            <Modal.Body>
                <div className="bodyCard">
                    <p className="text">A ligação terminou e o retorno automatico foi ativado, deseja manter?</p>
                    <p className="text">Telefone: <p className="textResult">{telefone}</p></p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={btnBlocked} style={{ backgroundColor: '#FF9C1A' }} onClick={async () => {
                    setBtnBlock(true);
                    clearTimeout(timerId.current);
                }}>
                    {/* apaga o telefone no storage */}
                    {btnBlocked ? 'Aguarde...' : 'Não retornar'}
                </Button>
                <Button disabled={btnBlocked} style={{ backgroundColor: '#00A443' }} onClick={async () => {
                    setBtnBlock(true);
                    clearTimeout(timerId.current);
                    await createCallbackConversation(telefone);
                }}>
                    {btnBlocked ? 'Aguarde...' : 'Manter retorno'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}


