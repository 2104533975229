import emitter from "../events/config";
import { eventsListener, getUrlParameters, sleep } from "../scripts"
import { isAuthenticated } from "../services/auth";
import { getConversationData } from "../services/conversation";
import { signChannelsEnd, singChannelsConversation } from "../services/notifications";
import { connectSocket } from "../services/socket";
import { getUserData } from "../services/users";

export const startflow = async () => {
    console.log('### Response: Iniciando startFlow')

    await isAuthenticated();
    emitter.emit('isAuthenticated', 'isAuthenticated')
    const { newSocketEnd, channel } = await connectSocket();

    const user = await getUserData();
    const userId = user.id;
    await singChannelsConversation(userId, channel.id);
    emitter.emit('signChannelsEnd', 'signChannelsEnd');
    console.log('### newSocketEnd ', newSocketEnd);

    const eventSocketPromise = new Promise((resolve) => {
        newSocketEnd.addEventListener('message', async (event) => {
            await eventsListener(event);
            resolve(event);
        });
    });

    const eventSocket = await eventSocketPromise;
    const dataParse = JSON.parse(eventSocket.data);
    const participants = dataParse.eventBody.participants;
    console.log('### eventSocket participants ', participants);
    emitter.emit('validateData', 'validateData');
    
    return participants;
}
